import validation_imports from "./validation_imports"
import transform_error_list from "../transform_errors_list"
import { DEFAULT_ERROR } from "../../constants/validation/error_types"
import { ADMIN } from "../../constants/others_constants"
import {
  social_media_provider_names, max_fb_video_size, max_ig_video_size,
  supported_ig_image_formats, supported_fb_image_formats,
  max_ig_video_duration, max_fb_video_duration, max_fb_image_size,
  max_ig_image_size, ig_image_dimensions
} from "../../constants/social_media_constants"

export default {
  ...validation_imports(ADMIN),
  validate() {

    // Clear error list and reset validator
    this.clear_errors("social_media_posts")

    // Defining validator without reactivity
    this.validator = true

    const facebook_channels = this.selected_channels.filter(({ provider }) => provider === social_media_provider_names.fb)
    const instagram_channels = this.selected_channels.filter(({ provider }) => provider === social_media_provider_names.ig)

    // Only FB errors
    if (facebook_channels.length && !instagram_channels.length) {
      // Check that video follows FB requirements
      if (
        this.uploaded_video &&
        (this.uploaded_video.size > max_fb_video_size || this.$refs.video_upload.duration > max_fb_video_duration)
      ) this.transforming_errors(
        DEFAULT_ERROR("social_media_posts", this.$translate("errors.social.video_max_size_reached", {
          provider: this.$translate("social_media_accounts.providers.fb"),
          size_limit: "1GB",
          duration_limit: `${max_fb_video_duration / 60}m`
        }))
      )

      // Check that images follow FB requirements
      if (
        this.uploaded_images.length &&
        this.uploaded_images.some(({ format, src, size }) =>
          !supported_fb_image_formats.includes(format || src.split(/[#?]/)[0].split('.').pop().trim()) ||
          max_fb_image_size < size
        )
      ) this.transforming_errors(
        DEFAULT_ERROR("social_media_posts", this.$translate("errors.social.image_doesnt_comply", {
          provider: this.$translate("social_media_accounts.providers.fb"),
          supported_formats: supported_fb_image_formats.join(", "),
          size_limit: "4mb"
        }))
      )
    }

    // IG errors
    if (instagram_channels.length) {
      // IG Media upload limit check
      if (this.uploaded_images.length > 1 && this.uploaded_video) this.transforming_errors(
        DEFAULT_ERROR("social_media_posts", this.$translate("errors.social.instagram_media_upload_limit"))
      )

      // Check that video follows IG requirements
      if (this.uploaded_video && (this.uploaded_video.size > max_ig_video_size || this.$refs.video_upload.duration > max_ig_video_duration)) this.transforming_errors(
        DEFAULT_ERROR("social_media_posts", this.$translate("errors.social.video_max_size_reached", {
          provider: this.$translate("social_media_accounts.providers.ig"),
          size_limit: "100mb",
          duration_limit: `${max_ig_video_duration}s`
        }))
      )

      // Check that image follows IG requirements
      if (this.uploaded_images.length) {
        const image = this.uploaded_images[0]
        const image_ratio = this.uploaded_images[0].width / this.uploaded_images[0].height

        if (
          // Format of file or of image url
          !supported_ig_image_formats.includes(image.format || image.src.split(/[#?]/)[0].split('.').pop().trim()) ||
          max_ig_image_size < image.size
        ) this.transforming_errors(
          DEFAULT_ERROR("social_media_posts", this.$translate("errors.social.image_doesnt_comply", {
            provider: this.$translate("social_media_accounts.providers.ig"),
            supported_formats: supported_ig_image_formats.join(", "),
            size_limit: "8mb"
          }))
        )

        if (
          image.width < ig_image_dimensions.w.min ||
          image.width > ig_image_dimensions.w.max ||
          image_ratio < ig_image_dimensions.ratio.min ||
          image_ratio > ig_image_dimensions.ratio.max
        ) this.transforming_errors(
          DEFAULT_ERROR("social_media_posts", this.$translate("errors.social.ig_image_dimensions_dont_comply", {
            width: "320px - 1440px",
            ratio: "4:5 - 1.91:1"
          }))
        )
      }
    }

    // General errors
    if (this.uploaded_images.length && this.uploaded_video) this.transforming_errors(
      DEFAULT_ERROR("social_media_posts", this.$translate("errors.social.only_video_or_images"))
    )

    if (!this.uploaded_images.length && !this.uploaded_video && !this.post_message) this.transforming_errors(
      DEFAULT_ERROR("social_media_posts", this.$translate("errors.social.empty_post"))
    )

    if (!this.selected_channels.length) this.transforming_errors(
      DEFAULT_ERROR("social_media_posts", this.$translate("errors.social.select_channel"))
    )
  
    return this.validator
  },
  transforming_errors(error, remove) {
    this.update_errors(transform_error_list(this.get_error_list(), error, remove, "social_media_posts"))
    this.validator = false
  }
}
