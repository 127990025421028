<template>
  <section class="social-media social-media-posts scroll-content">
    <h3 class="md-display-1">{{ $translate("social_media_posts.title") }}:</h3>

    <section_description_preview>
      <div class="md-subheading">{{ $translate("social_media_posts.message") }}</div>
    </section_description_preview>

    <md-tabs v-if="is_global_admin" class="social-media-ads__sections full-width-tabs-navigation" @md-changed="open_emoji_selector = false">

      <!-- Creating post -->

      <md-tab :md-label="$translate('social_media_posts.creating_posts')">
        <div class="social-media-posts__creation">
          <div>
            <md-field>
              <label>{{ $translate('social_media_posts.textarea_message') }}</label>
              <md-textarea v-model="post_message" ref="post_textarea"/>
            </md-field>

            <div class="social-media-posts__actions">
              <md-button class="md-icon-button" @click="open_media_upload_modal = true">
                <md-icon>insert_photo</md-icon>
                <md-tooltip md-direction="top">{{ $translate("social_media_posts.add_post_images") }}</md-tooltip>
              </md-button>
              <md-button class="md-icon-button" @click="() => { open_media_upload_modal = true; uploading_video = true; }">
                <md-icon>videocam</md-icon>
                <md-tooltip md-direction="top">{{ $translate("social_media_posts.add_post_video") }}</md-tooltip>
              </md-button>
              <div class="social-media-posts__emoji-button">
                <md-button class="md-icon-button" @click="() => open_emoji_selector = !open_emoji_selector">
                  <md-icon>sentiment_satisfied_alt</md-icon>
                  <md-tooltip md-direction="top">{{ $translate("social_media_posts.add_post_emoji") }}</md-tooltip>
                </md-button>
                <Emoji_picker v-if="open_emoji_selector" @select="add_emoji"/>
              </div>
              <md-button class="md-icon-button" @click="open_entity_selection_modal = true">
                <products_icon />
                <md-tooltip md-direction="top">{{ $translate("social_media_posts.create_from_product") }}</md-tooltip>
              </md-button>

              <md-button class="md-raised md-primary" @click="publish_post" :disabled="!permissions.social.sections.posts.manage">
                {{ $translate("social_media_posts.publish") }}
              </md-button>

              <entity_selection_modal
                :modal_title="$translate('social_media_posts.choose_produkt_or_collection')"
                :open_modal="open_entity_selection_modal"
                :should_fetch_data="true"
                @entity_selected="select_entity"
                @toggle_modal="val => open_entity_selection_modal = val"
              />
            </div>

            <div v-if="post_link" class="social-media-posts__link">
              {{ $translate("social_media_posts.link_title") }}:
              <a :href="post_link" target="_blank">{{ post_link }}</a>
            </div>
            
            <div
              v-if="uploaded_images.length && (!open_media_upload_modal || (open_media_upload_modal && uploading_video))"
              class="social-media-posts__photos"
            >
              <draggable v-model="uploaded_images" group="images" @start="drag=true" @end="drag=false">
                <transition-group>
                  <div
                    v-for="({ src, thumb_src }, index) in uploaded_images"
                    :key="`image-${index}`"
                    class="social-media-posts__media-preview"
                  >
                    <img
                      class="image-upload__image"
                      :src="thumb_src || src"
                    >
                    <md-button class="md-icon-button md-accent md-raised" @click="remove_media(index)">
                      <md-icon>delete_outline</md-icon>
                      <md-tooltip md-direction="top">{{ $translate("social_media_posts.remove_image") }}</md-tooltip>
                    </md-button>
                  </div>
                </transition-group>
              </draggable>
            </div>

            <div v-if="uploaded_video && (!open_media_upload_modal || (open_media_upload_modal && !uploading_video))">
              <div class="social-media-posts__media-preview">         
                <video controls ref="video_upload">
                  <source :src="uploaded_video.src">
                  Your browser does not support the video tag.
                </video>
                <md-button class="md-icon-button md-accent md-raised" @click="remove_media(null, true)">
                  <md-icon>delete_outline</md-icon>
                  <md-tooltip md-direction="top">{{ $translate("social_media_posts.remove_video") }}</md-tooltip>
                </md-button>
              </div>
            </div>

            <md-dialog :md-active.sync="open_media_upload_modal">
              <md-dialog-title>
                {{ $translate(`social_media_posts.upload_${uploading_video ? "video" : "image"}`) }}
              </md-dialog-title>

              <div>
                <div v-if="uploaded_images.length && !uploading_video" >
                  <div class="image-upload__image-wrapper">
                    <img
                      v-for="({ src }, index) in uploaded_images"
                      :key="`${src}-${index}`"
                      class="image-upload__image"
                      :src="src"
                    >
                  </div>
                  <md-button class="md-raised md-accent product-detail__gallery__button" @click="() => uploaded_images = []">
                    {{ $translate("clear_images") }}
                  </md-button>
                  <md-button class="md-raised md-primary product-detail__gallery__button" @click="open_media_upload_modal = false">
                    {{ $translate("social_media_posts.add_post_images") }}
                  </md-button>
                </div>
                <div v-else class="md-elevation-5 image-upload">
                  <input
                    v-if="uploading_video"
                    v-bind="media_upload_attributes"
                    @drop="event => add_media_files(event.target.files)"
                    @input="event => add_media_files(event.target.files)"
                    v-cloak
                  >
                  <input
                    v-else
                    v-bind="media_upload_attributes"
                    multiple
                    @drop="event => add_media_files(event.target.files)"
                    @input="event => add_media_files(event.target.files)"
                    v-cloak
                  >
                  <label for="image-upload">
                    <md-icon>cloud_upload</md-icon>
                    {{ $translate(`drag_and_drop_${uploading_video ? "video" : "image"}`) }}
                  </label>
                </div>
              </div>
            </md-dialog>
          </div>
          <div class="social-media-posts__creation-channels">
            <p class="md-title">
              {{ $translate("social_media_posts.channels_sharing") }}
            </p>
            <div
              v-for="provider in social_media_providers"
              :key="provider"
            >
              <div
                v-for="entity in social_platform_entities_with_content_mapper[provider]"
                v-show="get_correct_entities(provider, entity).length"
                :key="`${provider}_${entity}`"
              >
                <p class="md-subheading">
                  {{ `${$translate(`social_media_accounts.providers.${provider}`)} ${$translate(`social_media_accounts.entity_types.${entity}`)}` }}
                </p>
                <div class="social-media__platforms">
                  <md-content
                    v-for="{ id, name } in get_correct_entities(provider, entity)"
                    :key="id"
                    class="social-media__page md-elevation-3"
                    :class="{ 'social-media__page--selected': selected_channels.find(({ id: selected_id }) => selected_id === id) }"
                    @click="selected_channel(id, provider, entity)"
                  >
                    <p>{{ name }}</p>
                  </md-content>
                </div>
              </div>
            </div>
            <p
              v-if="!get_all_channels.length"
              class="social-media-posts__warning md-warning"
              v-html="$translate('social_media_posts.no_channels_connected')"
            />
          </div>
        </div>
      </md-tab>

      <!-- Previewing posts section -->

      <md-tab :md-label="$translate('social_media_posts.previewing_posts')">
        <p class="md-headline">{{ $translate("social_media_posts.select_social_media") }}:</p>
        <md-content
          v-for="platform in social_media_providers"
          :key="platform"
          class="social-media__platform md-elevation-3"
          :class="{ 'social-media__page--selected': chosen_provider === platform }"
          @click="chosen_provider = platform" 
        >
          <img :src="`/static/icons/social-media-${platform}.svg`" :alt="platform">
        </md-content>

        <div v-if="chosen_provider">
          <p class="md-headline">{{ $translate("social_media_posts.select_social_channel") }}:</p>
          <md-tabs :md-active-tab="chosen_entity_type" @md-changed="change_entity_type">
            <md-tab
              v-for="entity in social_platform_entities_with_content_mapper[chosen_provider]"
              :key="entity"
              :id="entity"
              :md-label="`${$translate(`social_media_accounts.providers.${chosen_provider}`)} ${$translate(`social_media_accounts.entity_types.${entity}`)}`"
            >
              <p class="md-title">
                {{ $translate("social_media_accounts.connected_entities", { entity: $translate(`social_media_accounts.entity_types.${entity}`) }) }}
              </p>
              <p class="md-body-1">
                {{ $translate("social_media_posts.click_to_show_posts") }}
              </p>
              <div v-if="correct_connected_entities.length" class="social-media__platforms">
                <md-content
                  v-for="{ id, name } in correct_connected_entities"
                  :key="id"
                  class="social-media__page md-elevation-3"
                  :class="{ 'social-media__page--selected': selected_entity_id === id}"
                  @click="show_entity_posts(id)"
                >
                  <p>{{ name }}</p>
                </md-content>
              </div>
              <p v-else>{{
                $translate(
                  "social_media_accounts.no_entities_connected",
                  {
                    source: $translate(`social_media_accounts.providers.${chosen_provider}`),
                    entity: $translate(`social_media_accounts.entity_types.${entity}`)
                  }
                )
              }}</p>
            </md-tab>
          </md-tabs>
          <div v-if="correct_posts.length" class="social-media-posts__posts">
            <md-table v-model="correct_posts" class="social-media-posts__table admin-table">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell :md-label="$translate(`social_media_posts.table.created_time`)">{{
                  $moment(item.created_time, orders_API_date_format).format(date_time_format)
                }}</md-table-cell>
                <md-table-cell :md-label="$translate(`social_media_posts.table.text_content`)">{{ item.message }}</md-table-cell>
                <md-table-cell :md-label="$translate(`social_media_posts.table.attachments`)">
                  <div v-if="item.attachments">
                    <span
                      v-for="({ media_type, media = {}, subattachments = {} }, ind) in item.attachments.data"
                      :key="`${item.id}_${ind}`"
                    >
                      <img v-if="['photo', 'video_autoplay', 'video', 'image'].includes(media_type.toLowerCase())" :src="media.image.src">
                      <span
                        v-else-if="media_type === 'album'"
                        v-for="({ type, media: sub_media }, ind) in subattachments.data"
                        :key="`${item.id}_${ind}`"
                      >
                        <img v-if="['photo', 'video_autoplay'].includes(type)" :src="sub_media.image.src">
                      </span>
                    </span>
                  </div>
                </md-table-cell>
                <md-table-cell :md-label="$translate(`social_media_posts.table.is_published`)">{{ item.is_published ? "✅" : "❌" }}</md-table-cell>
              </md-table-row>
            </md-table>
          </div>
          <p v-else-if="!correct_posts.length && this.selected_entity_id" class="md-subheading">
            {{ $translate("social_media_posts.no_posts_found") }}
          </p>
        </div>
      </md-tab>
    </md-tabs>

    <div v-else class="social-media__admin-message">
      <h3 class="md-headline">
        {{ $translate("global_admin_specific_module") }}
        <a class="md-headline" :href="`https://${admin_domain}/admin/${project_config.project_id}/social/posts`">Admin</a>
      </h3>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import { Picker } from "emoji-mart-vue"
import draggable from "vuedraggable"
import url_enity_encoder from "urlencode"
import { ADMIN, CONFIGURATION_STORE, SOCIAL_STORE, USER_STORE } from "../../../constants/others_constants"
import {
  PUBLISH_FACEBOOK_POSTS, FETCH_FACEBOOK_POSTS, FETCH_INSTAGRAM_POSTS, PUBLISH_INSTAGRAM_POSTS
} from "../../../stores/Admin/social/constants"
import {
  social_platform_entities_with_content_mapper, social_media_providers, social_media_provider_names,
  twitter_character_limit,
  fb_media_file_types
} from "../../../constants/social_media_constants"
import { collection, date_time_format, message_durations, orders_API_date_format, product, SHARED_STORE } from "../../../../Shared/constants/other"
import validate from "../../../methods/validations/social_media_posts"
import products_icon from "../../../../Shared/components/icon_components/product-icon"
import entity_selection_modal from "../entity_selection_modal"
import get_curly_brace_content from "../../../../Shared/methods/get_curly_brace_content"
import get_correct_translation_value from "../../../../Shared/methods/get_correct_translation_value"
import { FETCH_AND_ASSING_MOMENT_LIBRARY, UPDATE_LOADER } from "../../../stores/Admin/constants"
import create_file_from_image_src from "../../../methods/create_file_from_image_src"
import { admin_domain, image_dimension_names } from "../../../../../data/other_constants"
import get_image_src from "../../../../Shared/methods/get_image_src"
import section_description_preview from "../section_description_preview"

export default {
  components: {
    Emoji_picker: Picker,
    products_icon,
    entity_selection_modal,
    draggable,
    section_description_preview
  },
  data() {
    return {
      chosen_provider: null,
      chosen_entity_type: null,
      selected_entity_id: null,
      post_message: "",
      post_link: "",
      selected_channels: [],
      uploaded_images: [],
      uploaded_video: null,
      open_media_upload_modal: false,
      uploading_video: false,
      open_emoji_selector: false,
      open_entity_selection_modal: false,
      social_media_providers,
      social_platform_entities_with_content_mapper,
      date_time_format,
      orders_API_date_format,
      twitter_character_limit, // Add to textarea once Twitter integrated
      admin_domain
    }
  },
  computed: {
    ...mapState(SOCIAL_STORE, [
      "connected_fb_pages",
      "connected_fb_groups",
      "connected_ig_accounts",
      "fb_pages_posts",
      "fb_groups_posts",
      "ig_accounts_posts"
    ]),
    ...mapState(CONFIGURATION_STORE, [
      "project_config",
    ]),
    ...mapState(ADMIN, ["$moment"]),
    ...mapState(USER_STORE, ["permissions"]),
    ...mapState(SHARED_STORE, ["is_global_admin"]),
    correct_connected_entities() {
      return this.get_correct_entities(this.chosen_provider, this.chosen_entity_type)
    },
    get_all_channels() {
      return [
        ...this.connected_fb_pages,
        ...this.connected_fb_groups,
        ...this.connected_ig_accounts
      ]
    },
    correct_posts() {
      return (this[`${this.chosen_provider}_${this.chosen_entity_type}_posts`] || {})[this.selected_entity_id] || []
    },
    media_upload_attributes() {
      return {
        type: "file",
        accept: `${this.uploading_video ? "video" : "image"}/*`
      }
    }
  },
  watch: {
    chosen_provider() {
      this.chosen_entity_type = social_platform_entities_with_content_mapper[this.chosen_provider][0]
    }
  },
  async mounted() {
    await this.fetch_and_assing_moment()
  },
  beforeDestroy() {
    this.open_emoji_selector = false
  },
  methods: {
    ...validate,
    ...mapActions(SOCIAL_STORE, {
      fetch_fb_posts: FETCH_FACEBOOK_POSTS,
      publish_fb_posts: PUBLISH_FACEBOOK_POSTS,
      publish_ig_posts: PUBLISH_INSTAGRAM_POSTS,
      fetch_ig_posts: FETCH_INSTAGRAM_POSTS
    }),
    ...mapMutations(ADMIN, {
      update_loader: UPDATE_LOADER
    }),
    ...mapActions(ADMIN, {
      fetch_and_assing_moment: FETCH_AND_ASSING_MOMENT_LIBRARY
    }),
    show_entity_posts(entity_id) {
      this.selected_entity_id = entity_id

      this[`fetch_${this.chosen_provider}_posts`]([
        this.correct_connected_entities.find(({ id } = {}) => id === entity_id),
        this.chosen_entity_type === social_platform_entities_with_content_mapper[social_media_provider_names.fb][1]
      ])
    },
    change_entity_type(id) {
      this.selected_entity_id = null
      this.chosen_entity_type = id
    },
    get_correct_entities(provider, entity) {
      return this[`connected_${provider}_${entity}`] || []
    },
    selected_channel(selected_id, provider, entity) {
      if (
        this.selected_channels.find(({ id }) => id === selected_id)
      ) this.selected_channels = this.selected_channels.filter(({ id }) => id !== selected_id)
      else this.selected_channels.push({
        ...(this.get_all_channels.find(({ id }) => selected_id === id) || {}),
        provider, entity
      })
    },
    async publish_post() {
      if (!this.validate() || !this.permissions.social.sections.posts.manage) return setTimeout(
        () => this.clear_errors("social_media_posts") // Clear errors
      , message_durations.medium);

      const facebook_channels = this.selected_channels.filter(({ provider }) => provider === social_media_provider_names.fb)
      const instagram_channels = this.selected_channels.filter(({ provider }) => provider === social_media_provider_names.ig)

      if (facebook_channels.length) await this.publish_fb_posts([
        this.post_message,
        facebook_channels,
        this.uploaded_images,
        this.uploaded_video,
        this.post_link
      ])

      if (instagram_channels.length) await this.publish_ig_posts([
        this.post_message,
        instagram_channels,
        this.uploaded_images.length ? this.uploaded_images[0] : this.uploaded_video
      ])

      this.post_message = ""
      this.post_link = ""
      this.selected_channels = []
      this.uploaded_images = []
      this.uploaded_video = null
    },
    remove_media(index, is_video) {
      if (is_video) this.uploaded_video = null
      else this.uploaded_images = this.uploaded_images.filter((_, ind) => ind !== index)
    },
    add_emoji({ native }) {
      const new_post_message = this.post_message.split("")

      new_post_message.splice(this.$refs.post_textarea.$el.selectionStart, 0, `${native} `)

      this.post_message = new_post_message.join("")
    },
    add_media_files(files) {
      Array.from(files).forEach((file) => {
        try {
          this.update_loader()
          const reader = new FileReader()

          reader.addEventListener("load", () => {
            if (this.uploading_video) {
              this.uploaded_video = {
                src: reader.result,
                file: file,
                size: file.size,
                type: fb_media_file_types.videos,
                format: file.type.replace("video/", "")
              }
              this.open_media_upload_modal = false
              this.uploading_video = false
              this.update_loader(false)
            }
            else {
              const img = new Image();

              img.onload = () => {
                this.uploaded_images.push({
                  src: reader.result,
                  file: file,
                  type: fb_media_file_types.photos,
                  size: file.size,
                  height: img.height,
                  width: img.width,
                  format: file.type.replace("image/", "")
                })
                this.update_loader(false)
              }

              img.src = reader.result;
            }
          }, false)

          reader.readAsDataURL(file)
        } catch (error) {
          console.log(error)
        }
      })
    },
    async select_entity(collection_data, product_data, direct_to_product_or_collection, selecting_collection, selecting_parent_collection) {
      const parent_collection = get_curly_brace_content(collection_data.body_html || "")
      const category = selecting_parent_collection ?
        parent_collection[1] :
        parent_collection ? `${parent_collection[1]}/${collection_data.handle}` : collection_data.handle
      const sub_category = (selecting_parent_collection || selecting_collection) ? "" : product_data.id
      const post_text = selecting_parent_collection ?
        url_enity_encoder.decode(parent_collection[1]) :
        get_correct_translation_value(selecting_collection ? collection_data : product_data, "title")
      const final_route = direct_to_product_or_collection ? 
          `/?${selecting_collection ? collection : product}=${selecting_collection ? collection_data.handle : product_data.id}` :
          `/${category}/${sub_category}`

      this.post_link = `https://${this.project_config.domain}/Products${final_route}`
      this.post_message = post_text

      if (product_data && product_data.images) {
        this.update_loader()
        const image_files = await Promise.all(product_data.images.map(
          async (image) => {
          const correct_image_src = get_image_src(image, image_dimension_names.desktop)

          return await create_file_from_image_src(correct_image_src)
        }))
        this.update_loader(false)

        this.add_media_files(image_files)
      }

      this.open_entity_selection_modal = false
    },
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_global-constants" as *;
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/admin" as *;
  @use "../../../../../styles/admin_image_upload" as *;

  .social-media-posts {
    &__warning {
      padding: $half-default-size;
      border-radius: 2px;
    }

    &__photos {
      margin-bottom: $default-size;
    }

    &__link {
      margin-bottom: $double-default-size;

      a {
        display: block;
        max-width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__table {
      td {
        max-width: 400px;
        white-space: break-spaces;
        word-break: break-word;
      }

      .md-table-cell {
        span {
          margin-right: $half-default-size;
        }
        img {
          display: inline-block;
          max-width: 100px;
          max-height: 100px;
        }
      }
    }

    &__emoji-button {
      display: inline-block;
      position: relative;
    }

    &__sections {
      > .md-tabs-content {
        min-height: 100vh !important;
        overflow: visible;
      }
    }

    &__media-preview {
      position: relative;
      display: inline-block;
      margin: 0 $half-default-size $half-default-size 0;
      vertical-align: top;

      video {
        max-width: 200px;
        max-height: 200px;
        border-radius: $border-radius;
      }

      .image-upload__image {
        display: block;
        margin: 0;
        cursor: grab;

        &:active:hover {
          cursor: grabbing;
        }
      }

      .md-icon {
        min-width: $default-size;
        width: $default-size;
        height: $default-size;
        font-size: $default-size !important;
      }

      .md-icon-button {
        position: absolute;
        top: -12px;
        right: -15px;
        width: 30px;
        min-width: 30px;
        height: 30px;
        z-index: 1;
      }
    }

    &__actions {
      margin-bottom: $double-default-size;
    }

    &__actions {
      .md-icon-button {
        display: inline-block;
        height: 30px;
        width: 30px;
        min-width: 30px;
        border-radius: 0;

        .md-ripple {
          height: 30px;
          width: 30px;
          border-radius: 0;
        }

        .md-icon {
          height: 30px;
          width: 30px;
          font-size: 35px !important;
        }
      }

      .md-button {
        margin: $half-default-size $half-default-size 0 0;

        &:last-child {
          margin-right: 0;
          float: right;
        }
      }
    }

    &__creation {
      display: grid;
      grid-template-columns: 2.5fr 1.5fr;
      column-gap: $default-size;

      @media (max-width: $tablet) {
        grid-template-columns: 1fr;
      }

      .md-has-textarea {
        margin-bottom: 0;
      }

      &-channels {
        .md-subheading, .md-title {
          margin-top: 0;
        }
      }
    }
  }
</style>
