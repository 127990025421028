export default async src => {
  const correct_format = src.split("?alt")[0].split('.').pop().trim()
  const image_file = await fetch(src)
    .then(r => r.blob())
    .then(blobFile => new File(
        [blobFile],
        `image`, { type: `image/${correct_format === "jpg" ? "jpeg" : correct_format}` }
      )
    )

  return image_file
}